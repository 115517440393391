<header class="header">
  <div class="menu">
    <div
      *ngIf="shouldDisplayButtons"
      id="icon"
      class="icon"
      (click)="toggleMenu()"
      (keyup)="toggleMenu()"
      data.testid="header-menu"
      aria-hidden="true"
    >
      <img alt="Menu" src="assets/images/menu-icon.svg" />
    </div>
  </div>

  <div class="logo">
    <app-logo></app-logo>
  </div>

  <div class="account">
    <app-dropdown
      id="languages-dropdown"
      class="languages-dropdown"
      [options]="languageOptions"
      [selectedOption]="selectedLanguage"
      (selectedOptionChange)="didChangeLanguage($event)"
      [displayText]="updateDropdownLanguageText"
      testId="header-dropdown-language"
    >
    </app-dropdown>

    <app-header-user-information
      *ngIf="shouldDisplayButtons"
    ></app-header-user-information>
  </div>
</header>
