import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  @Input() isMultiple: boolean = false;
  @Input() acceptedFiles?: string;
  @Input() testId: string = '';
  @Output() selectionChanged = new EventEmitter<File | File[]>();

  currentSelected?: File | File[];

  onSelectionChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    const selected = input.files ? Array.from(input.files) : undefined;

    if (selected) {
      if (this.isMultiple) {
        this.currentSelected = selected;
      } else {
        this.currentSelected = selected[0];
      }
    } else {
      this.currentSelected = undefined;
    }

    this.selectionChanged.emit(this.currentSelected);
  }

  getCurrentSelectFileName() {
    if (Array.isArray(this.currentSelected)) {
      return this.currentSelected.map(x => x.name).join(', ');
    }

    return this.currentSelected?.name ?? 'FILE_UPLOAD.NO_FILES_SELECTED';
  }

  reset() {
    this.currentSelected = undefined;
  }
}
