<div class="radio-group">
  @for (item of options; track $index) {
    <app-checkbox
      type="radio"
      [groupId]="groupId"
      [label]="item.label"
      [state]="item.value === selected"
      (stateChange)="onStateChaged(item.value)"
    />
  }
</div>
