import { Component, Input } from '@angular/core';
import { TableCellColor } from '../../models/table-cell-color';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss'
})
export class TagComponent {
  @Input() color?: TableCellColor = TableCellColor.gray;
  @Input() grow?: boolean = false;
}
