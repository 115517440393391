import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CacheBase } from '../models/base/cache.base';
import { FunctionalityKeyValue } from '../models/functionality';

export const FUNCTIONALITIES_CACHE_KEY = 'FUNCTIONALITIES_CACHE';

@Injectable({
  providedIn: 'root',
})
export class FunctionalityService extends CacheBase<FunctionalityKeyValue> {
  constructor(private http: HttpClient) {
    super(FUNCTIONALITIES_CACHE_KEY);
  }

  list() {
    const request = this.http.get<FunctionalityKeyValue>(
      `${environment.auditBackendURL}listAllFunctionalities`
    );
    return this.handleCacheInRequest(request);
  }
}
