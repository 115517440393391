import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss',
  animations: [
    trigger('isOpenTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class PopupComponent implements OnChanges {
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Input() backdropOpacity: number = 0.5;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isOpen'].currentValue != changes['isOpen'].previousValue) {
      this.toggleEnableScroll()
    }
  }

  toggleIsOpen() {
    this.isOpen = !this.isOpen
    this.isOpenChange.emit(this.isOpen);
  }

  toggleEnableScroll() {
    if (this.isOpen) {
      document.body.style.overflow = "hidden";
      return
    }

    document.body.style.removeProperty("overflow");
  }

}
