<div class="toggle-wrapper">
  <p class="toggle-label">
    {{ label }}
  </p>
  <div class="toggle-switch" [ngClass]="{ checked: state }">
    <input
      type="checkbox"
      class="toggle-switch-checkbox"
      [name]="toggleName"
      [id]="toggleName"
      [value]="state"
      (change)="toggleState()"
    />
    <label class="toggle-switch-label" [attr.for]="toggleName">
      <span class="toggle-switch-inner"></span>
      <span class="toggle-switch-switch"></span>
    </label>
  </div>
</div>
