import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private requests = 0;
  private _isLoading = signal<boolean>(false);

  constructor() { }

  get isLoading() {
    return this._isLoading.asReadonly();
  }

  showLoader() {
    if (this.requests === 0) {
      this._isLoading.set(true);
    }

    this.requests++;
  }

  hideLoader() {
    this.requests--;

    if (this.requests === 0) {
      this._isLoading.set(false);
    }
  }
}
