import { catchError, throwError, map, Observable } from 'rxjs';

export class CacheBase<T> {
  cacheKey: string;

  constructor(cacheKey: string) {
    this.cacheKey = cacheKey;
  }

  handleCacheInRequest(request: Observable<T>) {
    return request.pipe(
      catchError((error) => {
        this.setCache(null);

        return throwError(() => error);
      }),
      map((x) => {
        this.setCache(x);

        return x;
      })
    );
  }

  getCache() {
    const item = window.localStorage.getItem(this.cacheKey);
    if (!item) {
      return null;
    }

    return JSON.parse(item) as T;
  }

  setCache(user: T | null) {
    window.localStorage.setItem(this.cacheKey, JSON.stringify(user));
  }

  removeCache() {
    window.localStorage.removeItem(this.cacheKey);
  }
}
