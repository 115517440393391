export const environment = {
  prod: true,
  msalConfig: {
    auth: {
      clientId: '8c7d3aa3-f9cd-480c-a53d-7f874d41545a',
      authority: 'https://login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d',
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  auditBackendURL: "api/"
};
