import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { UserService } from '../../api/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private userService: UserService
  ) {}

  login() {
    this.msalService.loginRedirect({
      ...this.msalGuardConfig.authRequest,
    } as RedirectRequest);
  }

  logout() {
    this.userService.removeCache();
    this.msalService.logoutRedirect();
  }

  isAuthenticated() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  getCurrentUser(): AccountInfo {
    if (this.isAuthenticated()) {
      return this.msalService.instance.getActiveAccount() as AccountInfo;
    }

    throw new Error("You must be logged in to access the current user");
  }
}
