import { Component, Input } from '@angular/core';

import { TableColumn } from '../../models/table-column';
import {
  AuditStatus,
  PromotionStatus,
  AuditStatusColors,
  PromotionStatusColors,
  Promotion,
} from '../../../api/models/promotion';
import { ONLY_DATE } from '../../constants/date-formats';
import { TableCellColor } from '../../models/table-cell-color';

@Component({
  selector: 'app-promotions-table',
  templateUrl: './promotions-table.component.html',
  styleUrl: './promotions-table.component.scss',
})
export class PromotionsTableComponent {
  @Input() promotions: Promotion[] = [];
  @Input() editColumn: TableColumn | undefined;

  auditStatusList = this.getStatus(Object.values(AuditStatus), AuditStatusColors, 'AUDIT_STATUS');
  promotionsStatusList = this.getStatus(Object.values(PromotionStatus), PromotionStatusColors, 'PROMOTION_STATUS');

  promoColumns: TableColumn[] = [
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-ID',
      key: 'id',
    },
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-NAME',
      key: 'name',
    },
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-TYPE',
      key: 'type',
    },
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-COUNTRY',
      key: 'country',
    },
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-LANGUAGE',
      key: 'language',
    },
    {
      type: 'date',
      label: 'DASHBOARD.PROMO-START-DATE',
      key: 'startDate',
      format: ONLY_DATE,
    },
    {
      type: 'date',
      label: 'DASHBOARD.PROMO-END-DATE',
      key: 'endDate',
      format: ONLY_DATE,
    },
    {
      type: 'date',
      label: 'DASHBOARD.PROMO-EXP-DATE',
      key: 'expirationDate',
      format: ONLY_DATE,
    },
    {
      type: 'date',
      label: 'DASHBOARD.PROMO-AUDIT-DUE-DATE',
      key: 'auditDueDate',
      format: ONLY_DATE,
    },
    {
      type: 'boolean',
      label: 'DASHBOARD.PROMO-AGENCY-STATUS',
      key: 'agencyStatus',
      textTrue: 'DASHBOARD.PROMO-ACTIVE',
      textFalse: 'DASHBOARD.PROMO-INACTIVE',
    },
    {
      type: 'enum',
      label: 'DASHBOARD.PROMO-STATUS',
      key: 'promotionStatus',
      values: this.promotionsStatusList
    },
    {
      type: 'enum',
      label: 'DASHBOARD.PROMO-AUDIT-STATUS',
      key: 'auditStatus',
      values: this.auditStatusList
    },
  ];

  getColumns() {
    if (this.editColumn) {
      return [...this.promoColumns, this.editColumn];
    }
    return this.promoColumns;
  }
  
  getStatus(
    status: string[], 
    colors: Record<string, TableCellColor>,
    statusKey: string,
  ): { key: string; value: string; color?: TableCellColor }[] {
    return status.map((key) => ({
      key,
      value: `${statusKey}.${key}`,
      color: colors[key],
    }));
  }
}