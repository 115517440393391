<button
  id="button"
  class="button"
  [ngClass]="{
    red: color === 'red',
    green: color === 'green',
    black: color === 'black',
    grow: grow === true,
    outlined: variant === 'outlined',
    small: size === 'small'
  }"
  (click)="click()"
  [type]="type"
  [disabled]="disabled"
  [attr.data-testid]="testId"
>
  <div class="content">
    <ng-content></ng-content>
  </div>
</button>
