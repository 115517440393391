import { Component, Input, OnChanges } from '@angular/core';
import { MenuService } from './service/menu.service';
import { MenuItem } from '../../models/menu-item';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../api/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent implements OnChanges {
  @Input() items?: MenuItem[];

  constructor(
    private menuService: MenuService,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  ngOnChanges(): void {
    if (this.items) {
      this.items = this.items.filter((item) => {
        if (!item.permissionsRequired) {
          return true;
        }

        return item.permissionsRequired.every((x) =>
          this.userService.userHasPermission(x.identifier, x.level)
        );
      });
    }
  }

  isOpen() {
    return this.menuService.isOpen();
  }

  didClickOption() {
    this.menuService.toggleMenu();
  }
}
