import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public isOpen = signal<boolean>(false);

  public toggleMenu() {
    this.isOpen.set(!this.isOpen())
  }

}
