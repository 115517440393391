import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  TableColumn,
  TableColumnEnum,
  TableColumnLimitedLinkText,
} from '../../models/table-column';
import { TranslateService } from '@ngx-translate/core';
import { TableCellColor } from '../../models/table-cell-color';
import { Subject } from 'rxjs';
import { TableColumnFilter } from './table-column-options-dropdown/table-column-options-dropdown.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent implements AfterContentChecked {
  @Input() columns?: TableColumn[];
  @Input() values?: unknown[];
  @Input() multipleSelection?: boolean;

  @Output() selectionChanged = new EventEmitter<unknown[]>();
  @Output() filtersChanged = new EventEmitter<{
    [filterQueryParamName: string]: string | string[];
  }>();

  tableCellColor: typeof TableCellColor = TableCellColor;
  selectedItems: unknown[] = [];
  filters: { [filterQueryParamName: string]: string | string[] } = {};
  textFilter = new Subject<{ filterQueryParamName: string; text: string }>();
  openedColumnOptions?: string;
  openedColumnOptionsPosition?: number;

  filterBodyWidth = 310;
  filterBodyPadding = 16;

  constructor(public translate: TranslateService) {}

  ngAfterContentChecked() {
    if (this.openedColumnOptions) {
      this.setColumnOptionsDropdownPosition();
    }
  }

  hasColorForEnumValue(column: TableColumnEnum, row: unknown) {
    return this.getColorForEnumValue(column, row) !== undefined;
  }

  getColorForEnumValue(column: TableColumnEnum, row: unknown) {
    return column.values.find(
      (x) => x.key === (row as keyof object)[column.key]
    )?.color;
  }

  getEnumValue(column: TableColumnEnum, row: unknown) {
    return (
      column.values.find((x) => x.key === (row as keyof object)[column.key])
        ?.value ?? ''
    );
  }

  onSelectionChange(item: unknown) {
    if (this.selectedItems.includes(item)) {
      this.selectedItems = this.selectedItems.filter(
        (selected) => selected !== item
      );
    } else {
      this.selectedItems.push(item);
    }

    this.selectionChanged.emit(this.selectedItems);
  }

  isItemSelected(item: unknown) {
    return this.selectedItems.includes(item);
  }

  clearAll() {
    this.selectedItems = [];
    this.selectionChanged.emit(this.selectedItems);
  }

  getTestId(column: TableColumn, rowIndex: number) {
    if (column.testId === undefined) {
      return null;
    }

    return `${column.testId}-${rowIndex}`;
  }

  getLinkTestId(
    column: TableColumn & TableColumnLimitedLinkText,
    rowIndex: number
  ) {
    if (
      column.testId === undefined ||
      column.linkTestId === undefined ||
      this.values === undefined
    ) {
      return null;
    }

    return `${column.testId}-${column.linkTestId(
      this.values[rowIndex]
    )}-${rowIndex}`;
  }

  getValueFromKey<T>(key: string, item: unknown) {
    if (!key.includes('.')) {
      return (item as keyof object)[key];
    }

    return key
      .split('.')
      .reduce(
        (acc, curr) => (acc ? (acc as keyof object)[curr] : undefined),
        item
      ) as T;
  }

  // Filtering definitions
  onSingleFilterChanged(filter: TableColumnFilter) {
    this.filters[filter.key] = filter.value;
    this.filtersChanged.emit(this.filters);
  }

  toggleColumnOptions(column: string) {
    if (this.openedColumnOptions === column) {
      this.openedColumnOptions = undefined;
      this.openedColumnOptionsPosition = 0;
    } else {
      this.openedColumnOptions = column;
    }
  }

  setColumnOptionsDropdownPosition() {
    const headerElement = document.querySelector(
      `#${this.openedColumnOptions}`
    );

    if (headerElement) {
      const parentOffset = (
        (headerElement as HTMLElement).offsetParent as HTMLElement
      ).offsetLeft;
      const headerLeft = headerElement.getBoundingClientRect().left;

      this.openedColumnOptionsPosition =
        headerLeft -
        parentOffset -
        this.filterBodyWidth / 2 +
        this.filterBodyPadding * 2;
    }
  }
}
