<div class="logo">
    <img alt="Coca Cola" class="coca-cola-image" src="assets/images/coca-cola-logo.svg"/>
    
    <div class="divider"></div>

    <div class="audit-portal">
        <img alt="Audit Portal" class="icon-image" src="assets/images/logo-icon.svg"/>

        <p class="text">
            <strong>Audit</strong> Portal
        </p>
    </div>
</div>
