<div class="menu" [class.open]="isOpen()">
    <div class="content">
        <div id="options" class="options">
            @for (item of items; track $index) {
                <div id="option" class="option">
                    <a id="anchor" [routerLink]="item.route" routerLinkActive="selected" (click)="didClickOption()">{{ item.label | translate }}</a>
                </div>
            }
        </div>
    </div>
</div>
