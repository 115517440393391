import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TableColumn } from '../../../models/table-column';
import { Subject, Subscription, debounceTime } from 'rxjs';

export type TableColumnFilter = {
  key: string;
  value: string | string[];
};

@Component({
  selector: 'app-table-column-options-dropdown',
  templateUrl: './table-column-options-dropdown.component.html',
  styleUrl: './table-column-options-dropdown.component.scss',
})
export class TableColumnOptionsDropdownComponent implements OnInit, OnDestroy {
  @Input() column?: TableColumn;
  @Input() testId = '';
  @Input() position?: number;
  @Input() isOpened = false;
  @Input() bodyWidth: number = 310;
  @Input() bodyPadding: number = 16;

  @Output() filterChanged = new EventEmitter<TableColumnFilter>();

  filter?: TableColumnFilter;
  textFilter = new Subject<string>();
  subscriptions: Subscription[] = [];

  constructor() {
    this.subscribeToTextFilterChanges();
  }

  ngOnInit(): void {
    if (this.column) {
      this.filter = {
        key: this.column.filterQueryParamName!,
        value: this.column.type === 'text' ? '' : [],
      };
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  subscribeToTextFilterChanges(): void {
    this.subscriptions = [
      ...this.subscriptions,
      this.textFilter.pipe(debounceTime(500)).subscribe((textFilter) => {
        this.filter!.value = textFilter ? textFilter : '';
        this.notifyFilterWasUpdated();
      }),
    ];
  }

  onTextFilterChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.textFilter.next(target.value);
  }

  notifyFilterWasUpdated() {
    this.filterChanged.emit(this.filter);
  }

  onEnumFilterChanged(value: string | number) {
    const enumFilter = this.filter?.value as string[];

    if (enumFilter.includes(value.toString())) {
      this.filter!.value = enumFilter.filter((x) => x !== value.toString());
    } else {
      this.filter!.value = [...enumFilter, value.toString()];
    }

    this.notifyFilterWasUpdated();
  }

  enumFilterIsSelected(value: string | number) {
    const enumFilter = this.filter!.value || [];
    return enumFilter.includes(value.toString());
  }
}
