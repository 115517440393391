<div
  *ngIf="show"
  class="temporary-message"
  [ngClass]="{
    success: kind === 'success',
    error: kind === 'error'
  }"
>
  {{ message | translate }}
</div>
