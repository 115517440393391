import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {
  @Input() label?: string;
  @Input() type: "checkbox" | "radio" = "checkbox";
  @Input() groupId: string = "";

  @Input() state = false;
  @Output() stateChange = new EventEmitter<boolean>();

  onStateChanged() {
    this.stateChange.emit(!this.state);
  }
}
