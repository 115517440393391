<div class="file-upload">
  <app-button (action)="input.click()" [testId]="testId">{{
    "FILE_UPLOAD.UPLOAD_BUTTON" | translate
  }}</app-button>

  <input
    class="file-upload-input"
    #input
    [multiple]="isMultiple"
    [accept]="acceptedFiles"
    type="file"
    (change)="onSelectionChanged($event)"
  />
  
  <p>{{ getCurrentSelectFileName() | translate }}</p>
</div>
