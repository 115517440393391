import { Component, Input, ViewEncapsulation } from '@angular/core';

import { Prize } from '../../../../api/models/prize';
import { TableColumn } from '../../../models/table-column';
import { Promotion } from '../../../../api/models/promotion';

@Component({
  selector: 'app-prizes-table',
  templateUrl: './prizes-table.component.html',
  styleUrl: './prizes-table.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PrizesTableComponent {
  @Input() prizes: Prize[] = [];
  @Input() editColumn: TableColumn | undefined;
  @Input() promotion: Promotion | undefined;

  prizeColumns: TableColumn[] = [
    {
      type: 'text',
      label: 'MANAGEMENT.PRIZE.TIER',
      key: 'tier',
    },
    {
      type: 'text',
      label: 'MANAGEMENT.PRIZE.ID',
      key: 'prizeId',
    },
    {
      type: 'text',
      label: 'MANAGEMENT.PRIZE.DESCRIPTION',
      key: 'description',
    },
    {
      type: 'radio-group',
      label: 'MANAGEMENT.PRIZE.IS-AUDITABLE',
      headerAlign: 'left',
      options: [
        { value: true, label: '' },
        { value: false, label: '' },
      ],
      initialOptionKey: 'auditable',
      testId: 'is-auditable-prize',
      onOptionChanged: this.updateAuditablePrize.bind(this),
      disabled: this.hasPromoStarted.bind(this),
      show: true,
    },
  ];

  getColumns() {
    if (this.editColumn) {
      return [...this.prizeColumns, this.editColumn];
    }
    return this.prizeColumns;
  }

  updateAuditablePrize(prize: unknown, auditable: unknown) {
    const prizeParsed: Prize = prize as Prize;
    const auditableParsed: boolean = auditable as boolean;

    const foundPrize = this.prizes.find(
      (p) => p.prizeId === prizeParsed.prizeId
    );

    if (foundPrize) {
      foundPrize.auditable = auditableParsed;
    }
  }

  hasPromoStarted(): boolean {
    if (!this.promotion || !this.promotion.startDate) {
      return false;
    }

    const today = new Date();
    const startDate = new Date(this.promotion.startDate);

    return startDate <= today;
  }
}
