<div
    class="tag"
    [ngClass]="{
        grow: grow,
        green: color === 'green',
        red: color === 'red',
        yellow: color === 'yellow',
        blue: color === 'blue',
        gray: color === 'gray'
    }"
>
    <ng-content></ng-content>
</div>