import { Component, EventEmitter, Input, Output } from '@angular/core';

export type ButtonColor = 'red' | 'green' | 'black';
export type ButtonVariant = 'solid' | 'outlined';
export type ButtonSize = 'normal' | 'small';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() color: ButtonColor = 'black';
  @Input() variant: ButtonVariant = 'solid';
  @Input() size: ButtonSize = 'normal';
  @Input() grow: boolean = false;
  @Input() type: string = 'button';
  @Input() disabled: boolean = false;
  @Input() testId: string = 'button';

  @Output() action = new EventEmitter<never>();

  click() {
    this.action.emit();
  }
}
