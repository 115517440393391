import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioOption } from '../../models/radio-option';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrl: './radio-group.component.scss'
})
export class RadioGroupComponent<T> {
  @Input() options: RadioOption<T>[] = [];
  @Input() groupId: string = "";
  @Input() selected?: T;

  @Output() optionChanged = new EventEmitter<T>();

  onStateChaged(value: T) {
    this.selected = value;
    this.optionChanged.emit(this.selected);
  }
}
