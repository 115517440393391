<div class="table-wrapper">
  @for (column of columns; track $index) { @if (column.filterable) {
  <app-table-column-options-dropdown
    [testId]="column.filterQueryParamName + '-filter'"
    [isOpened]="openedColumnOptions === column.filterQueryParamName!"
    [position]="openedColumnOptionsPosition"
    [column]="column"
    [bodyWidth]="filterBodyWidth"
    [bodyPadding]="filterBodyPadding"
    (filterChanged)="onSingleFilterChanged($event)"
  >
  </app-table-column-options-dropdown>
  } }

  <div class="filter-overlay" *ngIf="openedColumnOptions"></div>

  <div class="overflow-table-wrapper">
    <table id="table" class="table" *ngIf="columns && values">
      <thead>
        @if (multipleSelection === true) {
        <th class="checkbox"></th>
        } @for (column of columns; track $index) {
        <th
          [ngClass]="{
              'align-center': column.headerAlign === 'center' || column.headerAlign === undefined,
              'align-left': column.headerAlign === 'left',
              'align-right': column.headerAlign === 'right',
            }"
          [ngStyle]="{
            'min-width': column.minWidth ? column.minWidth + 'px' : 'unset'
          }"
          [id]="column.filterQueryParamName ?? ''"
          (click)="
            column.filterable
              ? toggleColumnOptions(column.filterQueryParamName!)
              : false
          "
        >
          <!-- filtering definitions -->
          @if (column.filterable) {
          <div class="column-options-wrapper">
            <div
              class="column-options-header"
              [ngClass]="{
                opened: openedColumnOptions === column.filterQueryParamName
              }"
            >
              <span
                [innerHTML]="column.label | translate | replaceNewlineWithBr"
              ></span>

              <img
                class="column-options-icon"
                src="../../../../assets/icons/arrow-down.svg"
                alt="Arrow"
              />
            </div>
          </div>
          } @else {
          <span
            [innerHTML]="column.label | translate | replaceNewlineWithBr"
          ></span>
          }
        </th>
        }
      </thead>

      <tbody>
        @for (row of $any(values); track row; let rowIndex = $index) {
        <tr>
          @if (multipleSelection === true) {
          <td class="checkbox">
            <app-checkbox
              (stateChange)="onSelectionChange(row)"
              [state]="isItemSelected(row)"
            ></app-checkbox>
          </td>
          } @for (column of columns; track column; let columnIndex = $index) {
          <td
            [ngClass]="{
              disabled: column.disabled && column.disabled(row) === true
            }"
          >
            @if (column.show === undefined || column.show === true) { @switch
            (column.type) { @case ('text') { @if (getValueFromKey(column.key,
            row) !== undefined && getValueFromKey(column.key, row) !== null) {
            <span [attr.testid]="getTestId(column, rowIndex)">
              {{ getValueFromKey(column.key, row) | translate }}
            </span>
            } @else {
            <ng-content *ngTemplateOutlet="none"></ng-content>
            } } @case ('date') { @if (row[column.key] !== undefined &&
            row[column.key] !== null) {
            <span [attr.testid]="getTestId(column, rowIndex)">
              @if (column.withoutTimezoneCorrection) {
              {{ row[column.key] | dateWithoutTimezone : column.format }}
              } @else if (column.customTimezone) {
              {{
                row[column.key]
                  | dateWithCustomTimezone
                    : column.customTimezone
                    : column.format
              }}
              } @else {
              {{ row[column.key] | date : column.format }}
              }
            </span>
            } @else {
            <ng-content *ngTemplateOutlet="none"></ng-content>
            } } @case ('boolean') { @if (row[column.key] === true ||
            row[column.key] === 1) {
            <app-tag
              [color]="tableCellColor.green"
              [grow]="true"
              [attr.testid]="getTestId(column, rowIndex)"
            >
              {{ column.textTrue | translate }}
            </app-tag>
            } @else if (row[column.key] === false || row[column.key] === 0) {
            <app-tag
              [color]="tableCellColor.red"
              [grow]="true"
              [attr.testid]="getTestId(column, rowIndex)"
            >
              {{ column.textFalse | translate }}
            </app-tag>
            } @else {
            <ng-content *ngTemplateOutlet="none"></ng-content>
            } } @case ('enum') { @if (row[column.key] !== undefined &&
            row[column.key] !== null) { @if (hasColorForEnumValue(column, row))
            {
            <app-tag
              [color]="getColorForEnumValue(column, row)"
              [grow]="true"
              [attr.testid]="getTestId(column, rowIndex)"
            >
              {{ getEnumValue(column, row) | translate }}
            </app-tag>
            } @else {
            <span [attr.testid]="getTestId(column, rowIndex)">
              {{ getEnumValue(column, row) | translate }}
            </span>
            } } @else {
            <ng-content *ngTemplateOutlet="none"></ng-content>
            } } @case ('icon-button') {
            <button
              class="button-icon"
              [attr.testid]="getTestId(column, rowIndex)"
              (click)="column.action(row)"
            >
              <img
                src="../../../../assets/icons/{{ column.iconName }}.svg"
                alt="Icon Name"
              />
            </button>
            } @case ('button') {
            <app-button
              [testId]="getTestId(column, rowIndex) || ''"
              (action)="column.action(row)"
              [color]="column.color || 'black'"
              [variant]="column.variant || 'solid'"
            >
              {{ column.text | translate }}
            </app-button>
            } @case ('limited-text') { @if (row[column.key] !== undefined &&
            row[column.key] !== null) { @if (row[column.key].length >
            column.charactersLimit) {
            {{ row[column.key] | slice : 0 : column.charactersLimit }}...
            <br />
            <a
              href="#"
              (click)="
                $event.preventDefault(); column.linkAction(row[column.key])
              "
              class="view-more-link"
              [attr.testid]="getTestId(column, rowIndex)"
            >
              {{ "TABLE.VIEW_MORE_LINK" | translate }}
            </a>
            } @else {
            <span [attr.testid]="getTestId(column, rowIndex)">
              {{ row[column.key] }}
            </span>
            } }} @case ('limited-link-text') { @if (row[column.key] !==
            undefined && row[column.key] !== null) {
            <a
              href="#"
              (click)="$event.preventDefault(); column.linkAction(row)"
              class="view-more-link"
              [attr.testid]="getTestId(column, rowIndex)"
            >
              @if (row[column.key].length > column.charactersLimit) {
              {{ row[column.key] | slice : 0 : column.charactersLimit }}... }
              @else {
              {{ row[column.key] }}
              }
            </a>
            <br />
            } @if ( column.hideLink === undefined || !column.hideLink(row) ||
            (column.forceShowLink !== undefined && column.forceShowLink(row)) ||
            (row[column.key] !== undefined && row[column.key] !== null &&
            row[column.key].length > column.charactersLimit) ) {
            <a
              href="#"
              (click)="$event.preventDefault(); column.linkAction(row)"
              class="view-more-link"
              [attr.testid]="getLinkTestId(column, rowIndex)"
            >
              <strong>{{ column.linkKey(row) | translate }}</strong>
            </a>
            } } @case ('radio-group') {
            <app-radio-group
              [groupId]="getTestId(column, rowIndex) || ''"
              [options]="column.options"
              [selected]="getValueFromKey(column.initialOptionKey, row)"
              (optionChanged)="column.onOptionChanged(row, $event)"
            ></app-radio-group>
            } @case ('link') {
            <a
              href="#"
              (click)="$event.preventDefault(); column.linkAction(row)"
              class="view-more-link"
              [attr.testid]="getTestId(column, rowIndex)"
            >
              {{ column.linkText | translate }}
            </a>
            } @case ('toggle') {
            <app-toggle
              [toggleName]="column.toggleNamePrefix + '-' + rowIndex"
              [state]="column.state(row)"
              (stateChange)="column.stateChange($event, row)"
            ></app-toggle>
            } } }
          </td>
          }
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>

<ng-template #none>
  <img src="../../../../assets/icons/remove.svg" alt="No value" />
</ng-template>
