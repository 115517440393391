<label id="checkbox" class="checkbox">
  <svg
    class="custom-checkbox"
    [ngClass]="{ active: state === true }"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    [attr.data-type]="type"
  >
    <path
      *ngIf="type === 'radio'"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C7.58172 4, 4 7.58172, 4 12C4 16.4183, 7.58172 20, 12 20C16.4183 20, 20 16.4183, 20 12C20 7.58172, 16.4183 4, 12 4ZM2 12C2 6.47715, 6.47715 2, 12 2C17.5228 2, 22 6.47715, 22 12C22 17.5228, 17.5228 22, 12 22C6.47715 22, 2 17.5228, 2 12Z"
    />
    <rect
      *ngIf="type === 'checkbox'"
      x="2"
      y="2"
      width="20"
      height="20"
      rx="2"
      fill="none"
      stroke="currentColor"
    />
    <path
      class="check-icon"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7071 8.79289C17.0976 9.18342 17.0976 9.81658 16.7071 10.2071L11.7071 15.2071C11.3166 15.5976 10.6834 15.5976 10.2929 15.2071L7.79289 12.7071C7.40237 12.3166 7.40237 11.6834 7.79289 11.2929C8.18342 10.9024 8.81658 10.9024 9.20711 11.2929L11 13.0858L15.2929 8.79289C15.6834 8.40237 16.3166 8.40237 16.7071 8.79289Z"
      fill="currentColor"
    />
  </svg>
  <span id="label" class="label" *ngIf="label">{{ label | translate }}</span>
  <input
    id="checkbox-input"
    class="native-input"
    [name]="groupId"
    [type]="type"
    [value]="state"
    (change)="onStateChanged()"
  />
</label>
