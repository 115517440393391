<div class="dropdown" [ngClass]="{
  'dropdown-open': open,
  'full-size': fullSize }
">
  <div
    id="header"
    class="header"
    (click)="handleClick()"
    (keyup)="handleClick()"
    aria-hidden="true"
    [ngClass]="{ 'header-open': open }"
    [attr.data-testid]="testId"
  >
    <div></div>
    <span class="title" [attr.data-testid]="testId + '-current-value'">
      @if (value) {
        {{ displayedValue | translate }}
      } @else {
        {{ placeholder | translate }}
      }
    </span>
    <img class="arrow" src="../../../../assets/icons/arrow-down.svg" alt="Arrow"/>
  </div>
  @if (open === true) {
    <div class="body">
      @for (item of options; track $index) {
      <div
        id="row"
        class="row"
        [ngClass]="{
          'no-checkbox': !withCheckbox,
          'selected': value === item.value
        }"
        (click)="handleValueChange(item.value)"
        (keyup)="handleValueChange(item.value)"
        aria-hidden="true"
        [attr.data-testid]="testId + '-' + item.value"
      >
        <app-checkbox *ngIf="withCheckbox" type="radio" [state]="value === item.value" />

        <div
          id="option"
          class="option"
          [ngClass]="{
                'green-style': item.colorStyle === 'green',
                'red-style': item.colorStyle === 'red',
              }"
        >
          <span
            class="text"
            [attr.data-testid]="testId + '-label-' + item.value"
            >{{ item.label | translate }}</span
          >
        </div>
      </div>
      }
    </div>
  }
</div>
