<div
  *ngIf="column"
  class="column-options"
  [style]="{
    left: position + 'px',
    width: bodyWidth + 'px',
    padding: bodyPadding + 'px'
  }"
  [ngClass]="{
    show: isOpened
  }"
>
  @if (column.type === 'enum') {
    <ul class="filter-enum-options" [attr.testid]="testId">
      @for (option of column.values; track $index) {
        <li
          tabindex="0"
          [attr.testid]="testId + '-' + $index"
          (keyup)="$event.key === 'Enter' ? onEnumFilterChanged(option.key) : false"
          (click)="onEnumFilterChanged(option.key)"
        >
          <span>
            @if (option.color) {
              <app-tag [color]="option.color" [grow]="true">
                {{ option.value | translate }}
              </app-tag>
            } @else {
              {{ option.value | translate }}
            }
          </span>
          <app-checkbox [state]="enumFilterIsSelected(option.key)">
          </app-checkbox>
        </li>
      }
    </ul>
  } @else {
    <div class="column-text-filter">
      <input
        [value]="filter?.value"
        (input)="onTextFilterChange($event)"
        [placeholder]="'TABLE.FILTER.TEXT.PLACEHOLDER' | translate"
        [attr.testid]="testId"
      />
      <img
        class="column-options-icon"
        src="../../../../../assets/icons/search.svg"
        [alt]="'TABLE.FILTER.TEXT.PLACEHOLDER' | translate"
      />
    </div>
  }
</div>
